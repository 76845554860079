<style lang="scss" scoped>
.card-body >>> table > tbody > tr > td {
	cursor: pointer;
}

.filter-container {
	display: flex;
	align-items: flex-end;

	button.map-btn {
		margin-bottom: 15px;
		width: 35px;
		height: 35px;
		margin-right: 20px;
	}
	.form-group {
		width: 100%;
	}
}

input[type='texte'] {
	width: 100%;
	padding: 12px 20px;
	margin: 4px 0;
	box-sizing: border-box;
	border: none;
	background-color: white;
	color: white;
}
.btn1 {
	margin-bottom: 10px;

	width: 250px;
}

.btn2 {
	margin-bottom: 10px;
	width: 100px;
}

.address-block {
	display: block;
	color: #323635;
}

.address-icon {
	top: 4px;
	left: 0;
	font-size: 24px;
	color: #999;
}

.address-content {
	margin-left: 32px;
}
</style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-row v-if="GreenSuppliers">
			<b-col cols="12" xl="12">
				<transition name="slide">
					<div>
						<div class="clearfix">
							<h1 :class="[`${!$screen.sm ? 'float-left main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">{{ this.caption1 }}</h1>
							<!--          <b-button v-if="isDev" v-b-toggle href="#LoadGreenKeySuppliers" @click.prevent class="float-right">{{FormMSG(50,'Load Green Key suppliers')}}</b-button>  -->
						</div>

						<!--         <b-collapse id="LoadGreenKeySuppliers">  -->
						<b-card no-body v-if="isDev">
							<b-card-body>
								<b-row v-if="isDev">
									<b-col sm="12">
										<h2 class="h4">
											{{ FormMSG(50, 'Load Green Key suppliers') }}
										</h2>
									</b-col>
									<b-col md="6">
										<b-form-group :label="FormMSG(5020, 'File to load')" label-for="accConfirmationFileBrowse" :label-cols="12">
											<b-form-file
												v-model="pdfFile"
												:browse-text="FormMSG(113, 'Browse')"
												name="test"
												id="accConfirmationFileBrowse"
												:accept="supportedMimeTypes"
												ref="pdfConfirmationFileId"
												@change="submitConfirmationFile"
												:placeholder="FormMSG(51, 'Choose a file or drop it here...')"
												:drop-placeholder="FormMSG(52, 'Drop file here...')"
											>
											</b-form-file>
										</b-form-group>
									</b-col>
									<b-col md="6">
										<b-form-group :label="FormMSG(5021, 'Filename')" label-for="docFileName" :label-cols="12">
											<b-form-input v-model="fileName" :disabled="true" type="text" id="docFileName"></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>
							</b-card-body>

							<b-modal
								header-class="header-class-modal-doc-package"
								:title="this.FormMSG(104, 'Success!')"
								class="modal-success"
								v-model="successModal"
								@ok="successModal = false"
								ok-variant="success"
								ok-only
							>
								{{ this.caption2 }}
							</b-modal>
						</b-card>
						<!--          </b-collapse>   -->

						<b-card no-body>
							<b-card-body>
								<b-row>
									<b-col sm="12">
										<h2 class="h4">{{ FormMSG(5024, 'Filter list') }}</h2>
									</b-col>
									<b-col sm="3">
										<b-form-group :label="this.FormMSG(80, 'Type')" label-align-sm="left" label-for="optionsForCategories">
											<b-form-select
												v-model="gsCategorySelected"
												:options="optionsForCategories"
												id="optionsForCategories"
												@change="gsCategoryChanged"
											></b-form-select>
										</b-form-group>
									</b-col>
									<b-col sm="3">
										<b-form-group :label="this.FormMSG(81, 'Country')" label-align-sm="left" label-for="optionsForCountries">
											<b-form-select
												v-model="countrySelected"
												:options="optionsForCountries"
												id="optionsForCountries"
												@change="countryChanged"
											></b-form-select>
										</b-form-group>
									</b-col>

									<b-col sm="3">
										<b-form-group :label="this.FormMSG(83, 'State')" label-align-sm="left" label-for="optionsForStates">
											<b-form-select
												v-model="stateSelected"
												:options="optionsForStates"
												id="optionsForStates"
												@change="stateChanged"
											></b-form-select>
										</b-form-group>
									</b-col>

									<b-col sm="3">
										<div class="filter-container">
											<b-button class="map-btn" @click="isMappedSupplierModalOpen = true">
												<i class="icon-map" />
											</b-button>
											<b-form-group :label="this.FormMSG(82, 'Text')" label-align-sm="left" label-for="filterInput">
												<b-input-group>
													<b-form-input
														v-model="filter"
														type="search"
														id="filterInput"
														:placeholder="FormMSG(44, 'Type to Search')"
													></b-form-input>
													<b-input-group-append>
														<b-button :disabled="!filter" @click="filter = ''">
															<i class="fa fa-times" aria-hidden="true"></i>
														</b-button>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</div>
									</b-col>
								</b-row>
							</b-card-body>
						</b-card>
						<div>
							<b-row v-if="NumGreenSuppliersFiltered > 0">
								<b-col cols="12" md="6" v-for="(item, index) in GreenSuppliersFiltered" :key="index">
									<b-card no-body class="mx-auto my-4 shadow card-tip">
										<b-card-body>
											<div>
												<strong class="h5">{{ item.name }}</strong>
											</div>

											<div>
												<strong class="text-primary">{{ GetTextFromMenuNumberAndMenuValue(1017, item.type) }}</strong>
											</div>
											<a href="#" class="address-block position-relative my-3" @click="goThere(item)">
												<div class="address-icon position-absolute">
													<!--<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 384 512" style="fill: #999" stroke="1">
                                                    <path d="M192 96c-52.935 0-96 43.065-96 96s43.065 96 96 96 96-43.065 96-96-43.065-96-96-96zm0 160c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64zm0-256C85.961 0 0 85.961 0 192c0 77.413 26.97 99.031 172.268 309.67 9.534 13.772 29.929 13.774 39.465 0C357.03 291.031 384 269.413 384 192 384 85.961 298.039 0 192 0zm0 473.931C52.705 272.488 32 256.494 32 192c0-42.738 16.643-82.917 46.863-113.137S149.262 32 192 32s82.917 16.643 113.137 46.863S352 149.262 352 192c0 64.49-20.692 80.47-160 281.931z"/>
                                                    </svg>-->
													<i class="icon-location-pin"></i>
												</div>
												<div class="address-content">
													{{ item.address.street }} , {{ item.address.zip }} {{ item.address.city }}<br />
													{{ item.address.state }}<br />
													{{ item.address.country }}
												</div>
											</a>
											<div>
												<a :href="item.webSite" target="_blank" class="d-inline-block text-truncate" style="max-width: 100%">{{
													item.webSite
												}}</a>
											</div>
										</b-card-body>
										<b-card-footer>
											<div style="line-height: 30px">
												<div class="row">
													<div class="col-6">
														<b-button variant="default" size="sm" @click="transferToProject(item.id)">
															<i class="icon-share mr-1"></i>
															{{ FormMSG(5027, 'Add to current project') }}
														</b-button>
													</div>
													<div class="col-6 green-key">
														<div class="pull-right" :id="`green-key-${index}`">
															<span class="key mr-2">
																<!-- <b-img
                                                rounded="circle"
                                                :src="getImageGreenKey()"
                                                width="16"
                                                height="16"
                                            /> -->
																<i class="fa fa-key" aria-hidden="true"></i>
															</span>
															<span class="label">{{ item.labelOrigin }}</span>
															<span class="d-flex text-label">{{ FormMSG(53, 'Label') }}</span>
														</div>
														<b-tooltip
															v-if="item.user.name != '' || item.user.firstName != ''"
															:target="`green-key-${index}`"
															placement="left"
														>
															Introduced by<br />{{ item.user.firstName }} {{ item.user.name }}
														</b-tooltip>
													</div>
												</div>
											</div>
										</b-card-footer>
									</b-card>
								</b-col>
							</b-row>
							<b-row v-else>
								<b-col>
									<div class="empty-state">
										{{ FormMSG(5525, 'Currently you have no green suppliers') }}
									</div>
								</b-col>
							</b-row>
							<loading :active.sync="isLoading" :is-full-page="true"></loading>
						</div>
					</div>
				</transition>
			</b-col>
		</b-row>

		<MappedSuppliersModal
			v-model="isMappedSupplierModalOpen"
			key="mapped-supplier-modal"
			:suppliers="GreenSuppliers"
			:title="FormMSG(8765786, 'Find your green supplier')"
			@selected="handleSelectedGreenSupplier"
		/>
	</errorContainer>
</template>

<script>
// import gql from 'graphql-tag'
import { store } from '@/store';

import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';

import { getGreenSuppliers, getGreenSuppliersCountries, getGreenSuppliersCountriesStates, transferGreenSupplierToProject } from '@/cruds/suppliers.crud';

import Loading from 'vue-loading-overlay';
import MappedSuppliersModal from '@/components/Modals/MappedSuppliersModal';
import { isFileSupported } from '@/utils/isFileSupported';

// import { isNil, objReducer } from '@/shared/utils'

// import axios from 'axios'
// import SupplierGreeenMapPin from '@/components/SupplierGreeenMapPin'

//const axios = require("axios").create();

export default {
	name: 'LoadGreenSupplier',
	components: {
		Loading,
		MappedSuppliersModal
		//SupplierGreeenMapPin
	},
	mixins: [languageMessages, globalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			hv: 'dark',
			isMappedSupplierModalOpen: false,
			erreur: {},
			GreenSuppliers: [],
			GreenSuppliersFiltered: [],
			rendSupplierType: 1,
			pdfFile: null,
			fileName: '',
			confirmation: '',
			currentPage: 1,
			perPage: 0,
			warning: '',
			filter: '',
			isLoading: false,
			stopPropagation: '',
			selectMode: 'single',
			selectedColor: 'primary',
			successModal: false,
			successModalDelete: false,
			currentSelectionId: 0,
			countrySelected: 'Belgium',
			emptyState: '----------',
			stateSelected: '----------',
			gsCategorySelected: 1,
			stateNames: [{ value: '----------', text: '----------' }],
			countryNames: [{ value: 'Belgium', text: 'Belgium' }], //,{value:"Italy",text:"Italy"}],
			selectedSupplierIdFromMap: null
		};
	},
	computed: {
		supportedMimeTypesList() {
			return [this.supportedMimeTypes];
		},
		supportedMimeTypes() {
			return '.xlsx,.xml';
		},
		caption1() {
			return this.FormMSG(40, 'Green suppliers');
		},
		caption2() {
			return this.FormMSG(41, 'This green supplier has been added to your projects');
		},
		isDev() {
			return store.isDev();
		},
		isProd() {
			return store.isProd();
		},
		optionsForCategories() {
			return this.FormMenu(1017);
		},
		optionsForCountries() {
			return this.countryNames;
		},
		optionsForStates() {
			return this.stateNames;
		},
		// NumGreenSuppliersFiltered: function () {
		// 	var filter = this.filter.trim().toLowerCase();
		// 	//filter="that";
		// 	// console.log("in GreenSuppliersFiltered filter:",filter);
		// 	// console.log("in GreenSuppliersFiltered this.GreenSuppliers:",this.GreenSuppliers);
		//
		// 	if (filter === '') {
		// 		this.GreenSuppliersFiltered = this.GreenSuppliers;
		// 		//  console.log("in2 GreenSuppliersFiltered this.GreenSuppliersFiltered:",this.GreenSuppliersFiltered);
		// 		return this.GreenSuppliersFiltered.length;
		// 	}
		//
		// 	this.GreenSuppliersFiltered = this.GreenSuppliers
		//       .filter(function (s) {
		//         //console.log("filter=",this.filter);
		//         console.log('s=', s);
		//         //return s.name.toLowerCase().indexOf(filter) > -1 || s.address.street.toLowerCase().indexOf(filter) > -1 ;
		//         return (
		//           s.name.toLowerCase().indexOf(filter) > -1 ||
		//           s.address.street.toLowerCase().indexOf(filter) > -1 ||
		//           (s.address.city.toLowerCase().indexOf(filter) > -1) | (s.address.zip.toLowerCase().indexOf(filter) > -1) ||
		//           s.address.state.toLowerCase().indexOf(filter) > -1
		//         );
		//       });
		// 	// console.log("in GreenSuppliersFiltered this.GreenSuppliersFiltered:",this.GreenSuppliersFiltered);
		// 	//console.log("ar:",ar);
		// 	return this.GreenSuppliersFiltered.length;
		// },
		NumGreenSuppliersFiltered: function () {
			var filter = this.filter.trim().toLowerCase();
			var selectedSupplierId = this.selectedSupplierIdFromMap;

			if (filter === '' && selectedSupplierId === null) {
				this.GreenSuppliersFiltered = this.GreenSuppliers;
				return this.GreenSuppliersFiltered.length;
			}

			this.GreenSuppliersFiltered = this.GreenSuppliers.filter(function (s) {
				var matchesFilter =
					s.name.toLowerCase().indexOf(filter) > -1 ||
					s.address.street.toLowerCase().indexOf(filter) > -1 ||
					s.address.city.toLowerCase().indexOf(filter) > -1 ||
					s.address.zip.toLowerCase().indexOf(filter) > -1 ||
					s.address.state.toLowerCase().indexOf(filter) > -1;

				var matchesSelectedSupplier = selectedSupplierId === null || s.id === selectedSupplierId;

				return matchesFilter && matchesSelectedSupplier;
			});

			return this.GreenSuppliersFiltered.length;
		},
		gsfields() {
			return [
				{
					key: 'name',
					label: this.FormMSG(2, 'Name'),
					sortable: true
				},
				{
					key: 'type',
					label: this.FormMSG(11, 'Type'),
					formatter: (value) => {
						return this.GetTextFromMenuNumberAndMenuValue(1017, value);
					},
					sortable: true
				},
				{
					key: 'address.street',
					label: this.FormMSG(3, 'Address'),
					sortable: true
				},
				{
					key: 'address.state',
					label: this.FormMSG(5, 'State'),
					sortable: true
				},
				{
					key: 'address.country',
					label: this.FormMSG(7, 'Country'),
					sortable: true
				},
				{
					key: 'webSite',
					label: this.FormMSG(6, 'Web site'),
					sortable: true
				},
				{
					key: 'goThere',
					label: this.FormMSG(9, 'Directions'),
					sortable: false,
					formatter: (v) => v
				},
				{
					key: 'addToMySuppliers',
					label: this.FormMSG(10, 'Add to my suppliers'),
					sortable: false,
					formatter: (v) => v
				}
			];
		}
	},
	created() {
		this.loadCountryNames();
		this.loadStateNames();
		this.reloadData();
	},
	watch: {
		filter() {
			this.selectedSupplierIdFromMap = null;
		}
	},
	methods: {
		/**
		 * @param {Object} supplier
		 */
		handleSelectedGreenSupplier(supplier) {
			this.selectedSupplierIdFromMap = supplier.id;
		},

		async transferToProject(gs_id) {
			//console.log("gs_id:",gs_id);
			//  var parsedSgId = parseInt(gs_id);
			//        console.log("parsedSgId:",parsedSgId);
			var result = await transferGreenSupplierToProject(gs_id);
			console.log('result:', result);
			if (result == true) {
				this.successModal = true;
			}
		},

		async reloadData() {
			this.GreenSuppliers = await getGreenSuppliers({
				name: this.countrySelected,
				state: this.stateSelected,
				type: this.gsCategorySelected
			});
			this.GreenSuppliersFiltered = this.GreenSuppliers;
			// console.log({
			//     name: this.countrySelected,
			//     state: this.stateSelected,
			//     type: this.gsCategorySelected,
			// })
		},

		rowClicked(item) {
			// copy item content into curDoc structure.
			//                this.$refs.myTable.clearSelected();
			//      console.log("item in rowClicked  this.$refs.myTable:",  this.$refs.myTable);
			//console.log("item in rowClicked:", item);
			this.currentSelectionId = item.id;
		},

		goThere(supplier) {
			//https://developers.google.com/maps/documentation/javascript/examples/marker-simple#maps_marker_simple-javascript

			// https://www.google.com/maps/search/?api=1&query=dieweg+85+uccle+1180+Bruxelles
			// https://www.google.com/maps/search/?api=1&query=dieweg+85+uccle+1180+Bruxelles
			//c
			// https://www.google.com/maps/search/?api=1&query=47.5951518,-122.3316393
			// http://www.google.com/maps/place/49.46800006494457,17.11514008755796
			console.log('  this.$options.name:', this.$options.name);
			console.log('supplier:', supplier);
			var filled = false;
			if (
				((typeof supplier.address.lat === 'string' && supplier.address.lat.length != 0) || supplier.address.lat != 0) &&
				((typeof supplier.address.lng === 'string' && supplier.address.lng.length != 0) || supplier.address.lng != 0)
			) {
				var urlStr = 'http://www.google.com/maps/place/' + supplier.address.lat + ',' + supplier.address.lng;
				console.log('urlStr:', urlStr);
				window.open(urlStr); // in a new window
			} else if (supplier.name.length > 0 || address.street.length > 0) {
				// build address string
				var urlStr = 'https://www.google.com/maps/search/?api=1&query=';
				if (supplier.name.length > 0) {
					urlStr += supplier.name;
					filled = true;
				}
				if (supplier.address.street.length > 0) {
					if (filled) {
						urlStr += '+';
					}
					urlStr += supplier.address.street;
				}
				if (supplier.address.number.length > 0) {
					if (filled) {
						urlStr += '+';
					}
					urlStr += supplier.address.number;
				}
				if (supplier.address.zip.length > 0) {
					if (filled) {
						urlStr += '+';
					}
					urlStr += supplier.address.zip;
				}
				if (supplier.address.city.length > 0) {
					if (filled) {
						urlStr += '+';
					}
					urlStr += supplier.address.city;
				}
				if (supplier.address.country.length > 0) {
					if (filled) {
						urlStr += '+';
					}
					urlStr += supplier.address.country;
				}
				//urlStr = escape(urlStr);
				console.log('urlStr:', urlStr);
				window.open(urlStr); // in a new window
			}
		},

		submitConfirmationFile(ev) {
			this.isLoading = true;
			if (ev.type == 'drop') {
				var docFil = event.dataTransfer.files[0];
			} else if (ev.type == 'change') {
				var docFil = ev.target.files[0];
			}
			if (isFileSupported(docFil, this.supportedMimeTypesList)) {
				//console.log("submitConfirmationFile submit file:", docFil);
				let formData = new FormData();
				formData.append('uploadimage', docFil);
				formData.append('fileName', docFil.name);
				this.fileName = docFil.name;
				formData.append('fileType', docFil.type);
				//console.log("in submitConfirmationFile, fileName:", this.fileName);
				var urlStr = process.env.VUE_APP_GQL + '/uploadGreenSuppliers';
				//console.log("in submitConfirmationFile, urlStr:", urlStr);
				//console.log("in submitConfirmationFile, formData:", formData);
				var axiosTmp = require('axios').create();

				axiosTmp
					.post(urlStr, formData, {
						headers: {
							Authorization: `Bearer ${store.state.token}`,
							'Content-Type': 'multipart/form-data'
						}
					})
					.then((result) => {
						console.log('submitConfirmationFile success result:', result);
						this.reloadData();
					})
					.catch(function (error) {
						alert('ERROR: the file was not updloaded to the server due to unknown error (reload the page and retry..)');
						//console.log(" submitConfirmationFile FAILURE!!: ",error);
					})
					.finally(() => (this.isLoading = false));
			} else {
				this.createToastForMobile(
					this.FormMSG(123, 'Error'),
					docFil.name + ' : ' + this.FormMSG(124, 'The file format is not supported. Please upload a supported file.'),
					'',
					'danger'
				);
				this.$refs.pdfConfirmationFileId.reset();
				this.isLoading = false;
			}
		},
		countryChanged() {
			console.log('country:', this.countrySelected);
			this.selectedSupplierIdFromMap = null;
			//this.stateSelected="----------"
			this.stateSelected = this.emptyState;
			this.loadStateNames();
			//this.stateSelected=this.stateNames[0].value
			//console.log("in country change, state selected:",this.stateSelected);
			this.reloadData();
		},
		stateChanged() {
			console.log('state:', this.stateSelected);
			this.selectedSupplierIdFromMap = null;
			this.reloadData();
		},
		gsCategoryChanged() {
			console.log('type:', this.gsCategorySelected);
			this.selectedSupplierIdFromMap = null;
			this.reloadData();
		},

		async loadCountryNames() {
			var ar = [];
			//console.log("in loadCountryNames");
			var countryData = await getGreenSuppliersCountries();
			//              console.log("in loadCountryNames countryData:",countryData);
			for (var i = 0; i < countryData.length; i++) {
				// adding item in countryOptions
				var curMenuItem = {};
				curMenuItem.value = countryData[i];
				curMenuItem.text = countryData[i];
				//console.log("in parseMenu, create curMenuItem for value:",curMenuItem.value);
				ar.push(curMenuItem);
			}
			this.countryNames = ar;
		},
		async loadStateNames() {
			var ar = [];
			//console.log("in loadStateNames this.countrySelected:",this.countrySelected);
			var stateData = await getGreenSuppliersCountriesStates(this.countrySelected);
			//console.log("in loadStateNames countryData:",stateData);
			for (var i = 0; i < stateData.length; i++) {
				// adding item in countryOptions
				var curMenuItem = {};
				curMenuItem.value = stateData[i];
				curMenuItem.text = stateData[i];
				//console.log("in parseMenu, create curMenuItem for value:",curMenuItem.value);
				ar.push(curMenuItem);
			}
			this.stateNames = ar;
		},
		getImageGreenKey() {
			return process.env.VUE_APP_GQL + '/images/' + 'gs-greenKey';
		}
	},
	filters: {
		upperCase(texte) {
			return texte.toUpperCase();
		}
	}
};
</script>
<style lang="scss" scoped>
.green-key {
	color: #3e7e26;
	> div:hover {
		cursor: pointer;
		color: #5dbd3a;
	}
	.key {
		font-size: 1rem;
	}
	.label {
		font-weight: 600;
	}
	.text-label {
		line-height: 10px;
		position: absolute;
		margin-top: -6px;
		margin-left: 50px;
		font-size: 0.835rem;
		color: rgba(0, 0, 0, 0.5);
		text-align: right;
	}
}
</style>
